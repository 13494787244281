import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './Collections.scss';

function CollectionsObjkt() {
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchCollectionsObjkt() {
      try {
        setLoading(true);
        const response = await fetch('https://data.objkt.com/v3/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            query: `
            query MyQuery {
              fa(
                where: {creator: {alias: {_eq: "roccano"}}, _not: {collection_id: {_in: ["61122", "83495", "60329", "91077"]}}}
                order_by: {items: desc_nulls_last}
              ) {
                items
                logo
                name
                contract
                collection_id
              }
            }            
            `,
          }),
        });

        const data = await response.json();
        setCollections(data.data.fa);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }

    fetchCollectionsObjkt();
  }, []);

  if (loading) return <div className='container'><p>Loading...</p></div>;
  if (error) return <div className='container'><p>Error: {error.message}</p></div>;

  return (
    <>
      <section className='section section--collections'>
        <div className='container'>
          <div className='collections'>
            <div className='row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 g-3 my-4'>
              {collections.map((collection, index) => (
                <div key={collection.name} className='col'>
                  <Link
                    to={`/objkt/collection?contract=${collection.contract}`}
                    className='collection-item p-3 h-100 justify-content-center card'

                  >
                    <LazyLoadImage effect='blur' className='collection-logo' src={'/collection/' + collection.contract + '.png'} alt={collection.name} />
                    <h2 className='collection-title'>{collection.name}</h2>
                    <p className='collection-items'>{collection.items} items</p>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>

      </section>
    </>
  );
}

export default CollectionsObjkt;
