import React, { } from 'react';
import CollectionFnd from '../components/CollectionFnd';


const PageCollectionFnd = () => {

  return (
    <div className="main">
      <div className='container'>
        <CollectionFnd />
      </div>
    </div>
  );
};

export default PageCollectionFnd;
