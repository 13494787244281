import React from 'react';
import { Helmet } from 'react-helmet';
import TokenComponentZora from '../components/TokenComponentZora';

const TokenZora = () => {

  return (

    <div className='main'>
      <Helmet>
        <title>Token</title>
      </Helmet>
      <TokenComponentZora />

    </div>
  );
};

export default TokenZora;
