import React from 'react';
import { Helmet } from 'react-helmet';
import Accordion from '../components/Accordion'; // Importa il componente Accordion
import './Home.scss';
import './Faq.scss';

const Faq = () => {
  return (
    <>
      <div className='main'>
        <Helmet>
          <title>Roccano</title>
        </Helmet>
        <div className='container'>
          <h1>Frequently Asked Questions</h1>
          <div className='wrap-faq'>
            <Accordion
              title="What is a NFT?"
              content={
                <>
                  <p>A <strong>NFT</strong>, or <strong>Non-Fungible Token</strong>, is a type of digital crypto asset that represents the unique ownership or provenance of a digital or real-world item, using <a href="https://en.wikipedia.org/wiki/Blockchain" target="_blank" rel="noopener noreferrer">blockchain technology</a>. Unlike cryptocurrencies like Bitcoin or Ethereum, which are fungible (interchangeable with each other), NFTs are unique and indivisible, each with a distinct value.</p>
                  <p>NFTs are often used to authenticate digital art, virtual collectibles, videos of sports moments, music, and more. Their uniqueness and traceability through the blockchain make them ideal for creating digital scarcity and authenticity.</p>
                  <p>For further information on NFTs, you can refer to the following reliable sources:</p>
                  <ul>
                    <li>
                      <a href="https://www.theverge.com/22310188/nft-explainer-what-is-blockchain-crypto-art-faq" target="_blank" rel="noopener noreferrer">The Verge: How Non-Fungible Tokens Work and Where They Get Their Value</a>
                    </li>
                    <li>
                      <a href="https://en.wikipedia.org/wiki/Non-fungible_token" target="_blank" rel="noopener noreferrer">Wikipedia: Non-fungible token</a>
                    </li>
                  </ul>
                </>
              }
            />
            <Accordion
              title="What is Tezos?"
              content={
                <>
                  <p><strong>Tezos (XTZ)</strong> is a blockchain platform and cryptocurrency designed to be self-amending and autonomous. Its key features include:</p>
                  <ul>
                    <li>On-Chain Governance: Tezos allows users to vote and propose upgrades to the blockchain, avoiding disputes like hard forks.</li>
                    <li>Smart Contracts: It supports smart contracts for creating decentralized applications.</li>
                    <li>Proof-of-Stake (PoS): It employs PoS to validate transactions, reducing environmental impact.</li>
                    <li>Security: Tezos uses formal verification to enhance security.</li>
                    <li>Interoperability: It's designed to be interoperable with other blockchains.</li>
                  </ul>
                  <p>For more information, you can visit the official Tezos website: <a href="https://tezos.com/" target="_blank" rel="noopener noreferrer">tezos.com</a>.</p>
                </>
              }
            />
            <Accordion
              title="What is Ethereum?"
              content={
                <>
                  <p><strong>Ethereum (ETH)</strong> is a decentralized blockchain platform and cryptocurrency. It is best known for its support of smart contracts, which are self-executing contracts with the terms of the agreement directly written into code. Ethereum's key features include:</p>
                  <ul>
                    <li>Smart Contracts: Ethereum enables developers to create decentralized applications (DApps) and digital agreements through smart contracts.</li>
                    <li>Decentralized Finance (DeFi): It has played a significant role in the growth of DeFi applications, allowing for financial activities like lending, borrowing, and trading without traditional intermediaries.</li>
                    <li>Non-Fungible Tokens (NFTs): Ethereum is the primary blockchain for NFTs, which represent ownership of unique digital assets like art, collectibles, and virtual real estate.</li>
                    <li>Decentralized Autonomous Organizations (DAOs): Ethereum supports the creation of DAOs, which are organizations governed by code and run on the blockchain.</li>
                  </ul>
                  <p>For more information about Ethereum, you can visit the official Ethereum website: <a href="https://ethereum.org/" target="_blank" rel="noopener noreferrer">ethereum.org</a>.</p>
                </>
              }
            />
            <Accordion
              title="What is an Ethereum and Tezos wallet?"
              content={
                <>
                  <p>An <strong>Ethereum wallet</strong> and a <strong>Tezos wallet</strong> are software applications that allow users to store, manage, and interact with their respective cryptocurrencies (ETH for Ethereum and XTZ for Tezos) and the assets and tokens built on these blockchain platforms. They also enable users to send and receive transactions, participate in smart contracts, and interact with decentralized applications (DApps) on their respective networks.</p>
                  <ul>
                    <li>
                      <strong>Metamask</strong>:
                      <ul>
                        <li><strong>Description:</strong> Metamask is a popular Ethereum wallet and browser extension that provides a user-friendly interface for managing Ethereum assets and interacting with Ethereum-based DApps. It also offers features like token management and a built-in decentralized exchange (DEX) for swapping tokens.</li>
                        <li><strong>Official Link:</strong> <a href="https://metamask.io/" target="_blank" rel="noopener noreferrer">Metamask Website</a></li>
                      </ul>
                    </li>
                    <li>
                      <strong>Temple Wallet</strong>:
                      <ul>
                        <li><strong>Description:</strong> Temple Wallet is a user-friendly Tezos wallet that allows users to manage their XTZ holdings and interact with Tezos-based DApps and smart contracts. It provides a simple and secure way to store and transact with XTZ.</li>
                        <li><strong>Official Link:</strong> <a href="https://templewallet.com/" target="_blank" rel="noopener noreferrer">Temple Wallet Website</a></li>
                      </ul>
                    </li>
                  </ul>
                  <p>These wallets are essential tools for anyone looking to engage with Ethereum or Tezos ecosystems, whether it's for storing cryptocurrency, participating in DeFi, collecting NFTs, or interacting with various blockchain applications. Always make sure to use official and trusted wallet software to ensure the security of your assets.</p>
                </>
              }
            />
            <Accordion
              title="What is Objkt.com?"
              content={
                <>
                  <p><strong>Objkt.com</strong> is a platform and marketplace for digital art and collectibles known as "OBJKTs." These OBJKTs are typically associated with the Tezos blockchain and are often non-fungible tokens (NFTs).</p>
                  <p>Artists and creators use Objkt.com to mint, list, and sell their digital art pieces as NFTs. Collectors can explore and purchase these unique digital creations, and each OBJKT is secured and proven to be authentic through the Tezos blockchain's technology.</p>
                  <p>For more details and to explore the world of digital art on Objkt.com, you can visit their website: <a href="https://objkt.com/" target="_blank" rel="noopener noreferrer">objkt.com</a>.</p>
                </>
              }
            />
            <Accordion
              title="What is Foundation.app?"
              content={
                <>
                  <p><strong>Foundation.app</strong> is a platform for creating, collecting, and trading digital art as non-fungible tokens (NFTs). It provides a marketplace and community for artists and collectors in the NFT space.</p>
                  <p>Artists can mint their digital creations as NFTs on Foundation.app, allowing them to tokenize their work and sell it to collectors. Collectors can acquire unique digital art pieces and participate in the vibrant NFT art scene.</p>
                  <p>For more details and to explore the world of digital art on Foundation.app, you can visit their website: <a href="https://foundation.app/" target="_blank" rel="noopener noreferrer">foundation.app</a>.</p>
                </>
              }
            />
            <Accordion
              title="How can I collect art on Objkt and Foundation?"
              content={
                <>
                  <p>To collect art on marketplaces like Objkt and Foundation, follow these general steps:</p>
                  <p><strong>Objkt.com:</strong></p>
                  <ol>
                    <li><strong>Create an Account:</strong> Visit Objkt.com and create an account if you don't already have one. You may need to connect your Tezos wallet to the platform.</li>
                    <li><strong>Browse and Discover:</strong> Explore the marketplace to discover digital art pieces listed as OBJKTs. You can browse through various categories and artists.</li>
                    <li><strong>Purchase OBJKTs:</strong> When you find an artwork you'd like to collect, click on it to view more details. If it's available for sale, you can use your Tezos wallet to purchase the OBJKT.</li>
                    <li><strong>Ownership:</strong> After purchase, the OBJKT will be associated with your Tezos wallet, proving your ownership on the Tezos blockchain.</li>
                  </ol>
                  <p><strong>Foundation.app:</strong></p>
                  <ol>
                    <li><strong>Create an Account:</strong> Go to Foundation.app and create an account. You might need to connect your Ethereum wallet to the platform.</li>
                    <li><strong>Explore Artworks:</strong> Start exploring the digital art pieces listed on Foundation. You can view the creations of various artists.</li>
                    <li><strong>Bid or Buy:</strong> If you find artwork you want to collect, you can place a bid or buy it directly using Ether (ETH) from your connected wallet. Bidding is common in auction-style sales.</li>
                    <li><strong>Ownership and NFT:</strong> Once you've successfully acquired an artwork, it will be associated with your Ethereum wallet as an NFT, proving your ownership on the Ethereum blockchain.</li>
                  </ol>
                  <p><strong>General Tips:</strong></p>
                  <ul>
                    <li>Be mindful of the cryptocurrency and wallet requirements for each platform (Tezos wallet for Objkt and Ethereum wallet for Foundation).</li>
                    <li>Verify the authenticity and details of the artworks you're interested in.</li>
                    <li>Participate in the communities associated with these platforms to learn more and connect with artists and collectors.</li>
                    <li>Keep track of your NFT collection within your respective wallets.</li>
                    <li>Understand the fees associated with buying, selling, and transferring NFTs on these platforms.</li>
                  </ul>
                  <p>Please note that the specific steps and features may evolve over time, so it's essential to check the platforms' official websites and documentation for the most up-to-date information and guidance. Additionally, exercise caution and conduct your research before making any purchases in the NFT market.</p>
                </>
              }
            />
            {/* Aggiungi altri accordion con titoli e contenuti desiderati */}
          </div>

        </div>

      </div>
    </>
  );
};

export default Faq;
