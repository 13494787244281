import React from 'react';
import { Helmet } from 'react-helmet';
import TokenComponent from '../components/TokenComponentObjkt';

const TokenObjkt = () => {

  return (

    <div className='main'>
      <Helmet>
        <title>Token</title>
      </Helmet>
      <TokenComponent />

    </div>
  );
};

export default TokenObjkt;
