import React from 'react';
import { Helmet } from 'react-helmet';
import CollectionsFnd from '../components/CollectionsFnd'


const Ethereum = () => {
  return (
    <>
      <div className='main'>
        <Helmet>
          <title>Roccano</title>
        </Helmet>
        <CollectionsFnd />
      </div>
    </>
  );
};

export default Ethereum;
