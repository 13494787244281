import React, { } from 'react';
import CollectionObjkt from '../components/CollectionObjkt';

const PageCollectionObjkt = () => {

  return (
    <div className="main">
      <CollectionObjkt />
    </div>
  );
};

export default PageCollectionObjkt;
