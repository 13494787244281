import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './TokenComponent.scss';
import { formatDistanceToNow } from 'date-fns';

const TokenComponentZora = () => {
  const [tokenData, setTokenData] = useState(null);
  const [contractParam, setContractParam] = useState(null);
  const [tokenParam, setTokenParam] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isImageFullScreen, setIsImageFullScreen] = useState(false);
  const [tokenComments, setTokenComments] = useState(null);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.keyCode === 27 && isImageFullScreen) {
        setIsImageFullScreen(false);
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [isImageFullScreen]);

  useEffect(() => {
    async function fetchData() {
      try {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        setContractParam(urlParams.get("contract"));
        setTokenParam(urlParams.get("token_id"));
        setLoading(true);

        // Fetch token data
        const tokenResponse = await fetch("https://api.zora.co/graphql", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `
              query tokenZora {
                aggregateStat {
                  ownersByCount1155(
                    where: {tokenId: "${urlParams.get('token_id')}", collectionAddress: "${urlParams.get('contract')}"}
                  ) {
                    latestMint
                    count
                    owner
                  }
                }
                token(
                  token: {address: "${urlParams.get('contract')}", tokenId: "${urlParams.get('token_id')}"}
                  network: {network: ZORA, chain: ZORA_MAINNET}
                ) {
                  token {
                    description
                    collectionName
                    collectionAddress
                    name
                    owner
                    tokenId
                    tokenStandard
                    tokenUrl
                    tokenUrlMimeType
                    image {
                      url
                    }
                  }
                }
              }
            `,
          }),
        });

        const tokenDataResponse = await tokenResponse.json();
        setTokenData(tokenDataResponse.data.token);

        // Fetch token comments
        const commentsResponse = await fetch("https://api.zora.co/graphql", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `
              query TokenComments {
                mintComments(
                  networks: {network: ZORA, chain: ZORA_MAINNET}
                  where: {collectionAddress: "${urlParams.get('contract')}", tokenId: "${urlParams.get('token_id')}"}
                ) {
                  comments {
                    blockNumber
                    collectionAddress
                    comment
                    fromAddress
                    quantity
                    tokenId
                    transactionInfo {
                      blockTimestamp
                    }
                  }
                }
              }
            `,
          }),
        });

        const commentsDataResponse = await commentsResponse.json();
        setTokenComments(commentsDataResponse.data.mintComments.comments);

      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }


    fetchData();
  }, [contractParam, tokenParam]);

  const calculateTimeDifference = (timestamp) => {
    return formatDistanceToNow(new Date(timestamp), { addSuffix: true });
  };

  if (loading) return <div className='container'><p>Loading...</p></div>;
  if (error) return <div className='container'><p>Error: {error.message}</p></div>;

  return (
    <>
      <Helmet>
        {tokenData && tokenData.token && (
          <title>{tokenData.token.name}</title>
        )}
      </Helmet>
      <section className='token-detail'>
        <div className='container'>
          <div className="token-detail-container py-4">
            {tokenData && tokenData.token && (
              <div>
                <div className='wrap-token-detail'>
                  <div className='img-token-detail'>
                    {isImageFullScreen ? (
                      <div
                        className={`fullscreen-image-overlay ${isImageFullScreen ? "visible" : ""}`}
                        onClick={(e) => {
                          if (isImageFullScreen) {
                            setIsImageFullScreen(false);
                          }
                        }}
                      >
                        <div onClick={() => setIsImageFullScreen(false)} className="close-fullscreen-image">
                          Close
                        </div>
                        <img
                          src={(tokenData.token.tokenUrlMimeType && tokenData.token.tokenUrlMimeType.startsWith('image/')) ? `/nft/${tokenData.token.image.url.replace("ipfs://", "")}.${tokenData.token.tokenUrlMimeType.split('/')[1] === 'jpeg' ? 'jpg' : tokenData.token.tokenUrlMimeType.split('/')[1]}` : `/nft/${tokenData.token.image.url.replace("ipfs://", "")}`}
                          alt={tokenData.token.name}
                          className='token-img-fullscreen'
                        />
                      </div>
                    ) : (
                      <div
                        onClick={() => setIsImageFullScreen(true)}
                        className="open-fullscreen-image"
                      >
                        <LazyLoadImage
                          effect='blur'
                          className='token-img'
                          src={(tokenData.token.tokenUrlMimeType && tokenData.token.tokenUrlMimeType.startsWith('image/')) ? `/nft/${tokenData.token.image.url.replace("ipfs://", "")}.${tokenData.token.tokenUrlMimeType.split('/')[1] === 'jpeg' ? 'jpg' : tokenData.token.tokenUrlMimeType.split('/')[1]}` : `/nft/${tokenData.token.image.url.replace("ipfs://", "")}`}
                          alt={tokenData.token.name}
                          loading="lazy"
                        />
                      </div>
                    )}
                  </div>
                  <div className='info-token-detail'>
                    <Link to={`/zora/collection?contract=${tokenData.token.collectionAddress}`}>
                      <h5 className='token-collection'>{tokenData.token.collectionName}</h5>
                    </Link>
                    <h2 className='token-name fw-bold'>{tokenData.token.name}</h2>
                    <p className='token-description pt-4'>{tokenData.token.description}</p>

                    <a
                      href={`https://zora.co/collect/zora:${tokenData.token.collectionAddress}/${tokenData.token.tokenId}`}
                      className="token-cta p-"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View on Zora
                    </a>
                  </div>
                </div>

              </div>
            )}
            {/* NavTab */}
            <Tabs className='pt-5'>
              <TabList>
                <Tab>Comments ({tokenComments ? tokenComments.length : 0})</Tab>
              </TabList>
              <TabPanel>
                {tokenComments && (
                  <div>
                    {tokenComments.map(comment => (
                      <div key={comment.blockNumber}>
                        <div className='wrap-comment'>
                          <div className='comment-avatar'><img src={`https://zora.co/api/avatar/${comment.fromAddress}?size=180`} alt='' /></div>
                          <div className='comment-txt'>{comment.comment}</div>
                          <div className='comment-time'>{calculateTimeDifference(comment.transactionInfo.blockTimestamp)}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </TabPanel>

            </Tabs>
          </div>
        </div>
      </section>
    </>
  );
};

export default TokenComponentZora;
