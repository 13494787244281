import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './CollectionFeatured.scss';
import gsap from 'gsap';

const CollectionFeatured = () => {
  const [tokenData, setTokenData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://data.objkt.com/v3/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            query: `
              query MyQuery {
                fa(where: {contract: {_eq: "KT1KoXChqEhdHcys9VC4oFV4AXVAX1ZDMWrT"}}) {
                  name
                  description
                  contract
                  tokens(limit: 10, order_by: {timestamp: desc}, where: {
                    supply: {_nin: "0"}
                    _not: {token_id: {_in: ["178"]}}
                  }) {
                    name
                    supply
                    mime
                    artifact_uri
                    token_id
                    listings(where: {status: {_eq: "active"}}, order_by: {price_xtz: asc}) {
                      price_xtz
                    }
                    offers_active {
                      price_xtz
                    }
                  }
                }
              }
            `,
          }),
        });

        const data = await response.json();

        // Seleziona 3 token casuali per ogni collezione
        const randomTokensData = data.data.fa.map(fa => ({
          ...fa,
          tokens: fa.tokens.sort(() => 0.5 - Math.random()).slice(0, 3)
        }));

        setTokenData(randomTokensData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching fa data:', error);
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const formatPrice = (price) => {
    const formattedPrice = (price / 1000000).toFixed(2);
    const finalPrice = formattedPrice.endsWith('.00') ? formattedPrice.split('.')[0] : formattedPrice;
    return finalPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  useEffect(() => {
    gsap.to('.collection-featured', { duration: 1, opacity: 1, y: 0, ease: "elastic.out(1,0.3)", delay: .5, });
  }, []);

  return (
    <>
      <section className='collection-featured'>
        <div className='container'>
          {loading && <p>Loading...</p>}
          {error && <p>Error: {error.message}</p>}
          {!loading && !error && (
            <div className='card p-3'>
              <div className='row row-cols-1 row-cols-sm-2 g-2 align-items-center'>
                {tokenData.map((fa, index) => (
                  <div key={index} className='col'>
                    <h2 className='fw-bold'>{fa.name}</h2>
                    <p className='fs-5'>{fa.description}</p>
                  </div>
                ))}
                {tokenData.map((fa, index) => (
                  <div key={index} className='col col-cta'>
                    <Link className='btn btn-outline-dark' to={`/objkt/collection?contract=${fa.contract}`}>
                      Discover collection
                    </Link>
                  </div>
                ))}
              </div>
              <div className='row row-cols-1 row-cols-sm-1 row-cols-md-3 g-3'>
                {tokenData.map((fa, index) => (
                  fa.tokens.map((token, tokenIndex) => (
                    <div key={tokenIndex} className='col'>
                      <div className='card'>
                        <Link to={`/objkt/token?contract=${fa.contract}&token_id=${token.token_id}`}>
                          <LazyLoadImage
                            effect='blur'
                            className='card-img-top'
                            src={(token.mime && token.mime.startsWith('image/')) ? `/nft/${token.artifact_uri.replace("ipfs://", "")}.${token.mime.split('/')[1] === 'jpeg' ? 'jpg' : token.mime.split('/')[1]}` : `/nft/${token.artifact_uri.replace("ipfs://", "")}`}
                            alt={token.name}
                            loading="lazy"
                          />
                        </Link>
                        <div className='card-body'>
                          <div className='d-flex justify-content-between align-items-center'>
                            <div className='card-title fs-5 fw-bold'>
                              <Link to={`/tezos/token?contract=${fa.contract}&token_id=${token.token_id}`}>
                                {token.name}
                              </Link>
                            </div>
                            <div className='card-subtitle text-body-secondary fs-6'>{token.supply}x</div>
                          </div>
                          <div className='d-flex justify-content-between py-1 fs-6'>
                            <div className='d-flex flex-column'>
                              <div className='text-body-secondary'>Price:</div>
                              <div className='fw-bold'>{token.listings && token.listings.length > 0 ? formatPrice(token.listings[0].price_xtz) + ' XTZ' : '-'}</div>
                            </div>
                            <div className='d-flex flex-column align-items-end'>
                              <div className='text-body-secondary'>Offer:</div>
                              <div className='fw-bold'>
                                {token.offers_active.length > 0 ? formatPrice(token.offers_active[0].price_xtz) + ' XTZ' : '-'}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ))}
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default CollectionFeatured;
