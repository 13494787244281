import React from 'react';
import { Helmet } from 'react-helmet';
import TokenComponentFnd from '../components/TokenComponentFnd';

const TokenFnd = () => {

  return (
    <div className='main'>
      <Helmet>
        <title>Token</title>
      </Helmet>
      <div className='container'>
        <TokenComponentFnd />
      </div>
    </div>
  );
};

export default TokenFnd;
