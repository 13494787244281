import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './CollectionComponent.scss';

const CollectionComponent = () => {
  const [nftList, setNftList] = useState([]);
  const [contractParam, setContractParam] = useState(null);
  const [nftCount, setNftCount] = useState(0);


  useEffect(() => {
    async function fetchCollectionData() {
      try {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const contract = urlParams.get("contract");
        if (!contract) return;

        setContractParam(contract);

        const response = await fetch("https://api.zora.co/graphql", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `
              query fetchNft {
                aggregateStat {
                  nftCount(
                    where: {collectionAddresses: "${contract}"}
                    networks: {network: ZORA, chain: ZORA_MAINNET}
                  )
                }
                tokens(
                  networks: {network: ZORA, chain: ZORA_MAINNET}
                  where: {collectionAddresses: "${contract}"}
                  pagination: {limit: 30}
                  sort: {sortKey: NONE, sortDirection: DESC}
                ) {
                  nodes {
                    token {
                      collectionAddress
                      collectionName
                      description
                      name
                      tokenId
                      tokenUrlMimeType
                      image {
                        url
                      }
                    }
                  }
                }
              }
            `
          }),
        });

        const responseData = await response.json();

        if (response.ok) {
          setNftList(responseData.data.tokens.nodes);
          setNftCount(responseData.data.aggregateStat.nftCount);
        } else {
          throw new Error(responseData.errors[0].message);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchCollectionData();
  }, []);

  return (
    <>
      <Helmet>
        {nftList.map(collection => (
          <title key={collection.token.tokenId}>{collection.token.collectionName}</title>
        ))}
      </Helmet>



      <section className='section section--collection-detail'>
        <div className='container'>
          <div className="collection-detail">
            {nftList.length > 0 &&
              <div className='wrap-collection-detail my-4'>
                <LazyLoadImage effect='blur' className='collection-logo' src={'/collection/' + contractParam + '.png'} alt={`${nftList[0].token.collectionName} Logo`} />
                <h1 className='collection-name fw-bold'>{nftList[0].token.collectionName}</h1>
                {/* Aggiungi altri dettagli della collezione qui */}
                <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-6 g-3 my-4 justify-content-center">
                  <div className='col'>
                    <div className='card p-2 h-100 justify-content-center'>
                      <span className='fs-4'>Items</span>
                      <span className="fw-bold fs-5">{nftCount}</span>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </section>
      <section className='section section--collection-listing'>
        <div className='container'>
          <div className='row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 g-3'>
            {nftList.map((nft) => (
              <div className='col' key={nft.token.tokenId}>
                <div className='card d-flex justify-content-center'>
                  <Link to={`/zora/token?contract=${nft.token.collectionAddress}&token_id=${nft.token.tokenId}`}>
                    <LazyLoadImage
                      effect='blur'
                      className='token-img'
                      src={(nft.token.tokenUrlMimeType && nft.token.tokenUrlMimeType.startsWith('image/')) ? `/nft/${nft.token.image.url.replace("ipfs://", "")}.${nft.token.tokenUrlMimeType.split('/')[1] === 'jpeg' ? 'jpg' : nft.token.tokenUrlMimeType.split('/')[1]}` : `/nft/${nft.token.image.url.replace("ipfs://", "")}`}
                      alt={nft.token.name}
                      loading="lazy"
                    />
                  </Link>
                  <div className='card-body'>
                    <div className='d-flex justify-content-between align-items-center'>
                      <div className='card-title fs-4 fw-bold'>
                        <Link to={`/zora/token?contract=${nft.token.collectionAddress}&token_id=${nft.token.tokenId}`}>{nft.token.name}</Link>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default CollectionComponent;
