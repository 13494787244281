import React, { } from 'react';
import CollectionZora from '../components/CollectionZora';

const PageCollectionZora = () => {

  return (
    <div className="main">
      <CollectionZora />
    </div>
  );
};

export default PageCollectionZora;
