import React from 'react';
import { Helmet } from 'react-helmet';
import './Home.scss';
import Hero from '../components/Hero';
import CollectionFeatured from '../components/CollectionFeatured';
const Home = () => {
  return (
    <>
      <div className='main'>
        <Helmet>
          <title>Roccano</title>
        </Helmet>
        <Hero></Hero>
        <CollectionFeatured></CollectionFeatured>
      </div>
    </>
  );
};

export default Home;
