import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './CollectionComponent.scss';

const CollectionComponent = () => {
  const [collectionData, setCollectionData] = useState([]);
  const [nftList, setNftList] = useState([]);
  const [contractParam, setContractParam] = useState(null);
  const [listingLoading, setListingLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const loaderRef = useRef(null);

  useEffect(() => {
    async function fetchCollectionData() {
      try {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const contract = urlParams.get("contract");
        setContractParam(contract);

        const response = await fetch("https://data.objkt.com/v3/graphql", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `
              query fetchCollectionData {
                fa(where: {contract: {_eq: "${contract}"}}) {
                  volume_24h
                  volume_total
                  description
                  items
                  name
                  logo
                  active_listing
                  floor_price
                  owners
                }
              }
            `
          }),
        });

        const { data } = await response.json();
        setCollectionData(data.fa);
      } catch (error) {
        console.error(error);
      }
    }

    fetchCollectionData();
  }, []);

  useEffect(() => {
    async function fetchNftList() {
      try {
        if (!contractParam || !hasMore) return;

        setListingLoading(true);

        const response = await fetch("https://data.objkt.com/v3/graphql", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `
              query fetchNftList($limit: Int!, $offset: Int!) {
                fa(where: {contract: {_eq: "${contractParam}"}}) {
                  tokens(
                    limit: $limit,
                    offset: $offset,
                    order_by: { timestamp: desc },
                    where: {
                      supply: {_nin: "0"},
                      _not: {token_id: {_in: ["178"]}}
                    }
                  ) {
                    artifact_uri
                    display_uri
                    mime
                    name
                    supply
                    token_id
                    fa_contract
                    listings(where: {status: {_eq: "active"}}, order_by: {price_xtz: asc}) {
                      price_xtz
                      status
                    }
                    offers_active {
                      price_xtz
                    }
                  }
                }
              }
            `,
            variables: { limit: 12, offset }
          }),
        });

        const { data } = await response.json();

        if (data && data.fa && data.fa[0] && data.fa[0].tokens) {
          setNftList(prevList => [...prevList, ...data.fa[0].tokens.filter(token => !prevList.some(prevToken => prevToken.token_id === token.token_id))]);
          setHasMore(data.fa[0].tokens.length > 0);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setListingLoading(false);
      }
    }

    fetchNftList();
  }, [contractParam, offset, hasMore]);

  useEffect(() => {
    const handleScroll = () => {
      if (loaderRef.current && window.innerHeight + window.scrollY >= loaderRef.current.offsetTop) {
        if (!listingLoading) {
          setOffset(prevOffset => prevOffset + 12);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [listingLoading]);

  const formatPrice = (price) => {
    const formattedPrice = (price / 1000000).toFixed(2);
    const finalPrice = formattedPrice.endsWith('.00') ? formattedPrice.split('.')[0] : formattedPrice;
    return finalPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  return (
    <>
      <Helmet>
        {collectionData.map(fa => (
          <title key={fa.name}>{fa.name}</title>
        ))}
      </Helmet>
      <main>
        <section className='section section--collection-detail'>
          <div className='container'>
            <div className="collection-detail">
              {collectionData.map(fa => (
                <div className='wrap-collection-detail' key={fa.items}>
                  <LazyLoadImage effect='blur' className='collection-logo' src={'/collection/' + contractParam + '.png'} alt={`${fa.name} Logo`} />
                  <h1 className='collection-name fw-bold'>{fa.name}</h1>
                  <p className="collection-description">{fa.description}</p>
                  <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-6 g-3 my-4">
                    <div className='col'>
                      <div className='card p-2 h-100 justify-content-center'>
                        <span className='fs-4'>Items</span>
                        <span className="fw-bold fs-5">{fa.items !== 0 ? fa.items : 0}</span>
                      </div>
                    </div>
                    <div className='col'>
                      <div className='card p-2 h-100 justify-content-center'>
                        <span className='fs-6'>Volume Total</span>
                        <span className="fw-bold fs-5">{parseInt(fa.volume_total) !== 0 ? parseInt(fa.volume_total).toString().slice(0, -6) + "" : 0} <small className='fs-6'>XTZ</small></span>
                      </div>
                    </div>
                    <div className='col'>
                      <div className='card p-2 h-100 justify-content-center'>
                        <span className='fs-6'>Volume 24h</span>
                        <span className="fw-bold fs-5">{parseInt(fa.volume_24h) !== 0 ? parseInt(fa.volume_24h).toString().slice(0, -6) + "" : 0} <small className='fs-6'>XTZ</small></span>
                      </div>
                    </div>
                    <div className='col'>
                      <div className='card p-2 h-100 justify-content-center'>
                        <span className='fs-6'>Owners</span>
                        <span className="fw-bold fs-5">{fa.owners !== 0 ? fa.owners : 0}</span>
                      </div>
                    </div>
                    <div className='col'>
                      <div className='card p-2 h-100 justify-content-center'>
                        <span className='fs-6'>For Sale</span>
                        <span className="fw-bold fs-5">{fa.active_listing !== 0 ? fa.active_listing : 0}</span>
                      </div>
                    </div>
                    <div className='col'>
                      <div className='card p-2 h-100 justify-content-center'>
                        <span className='fs-6'>Floor Price</span>
                        <span className="fw-bold fs-5">{parseInt(fa.floor_price) !== 0 ? parseInt(fa.floor_price).toString().slice(0, -6) + "" : 0} <small className='fs-6'>XTZ</small></span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section className='section section--collection-listing'>
          <div className='container'>
            <div className='row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 g-3'>
              {nftList.map((nft) => (
                <div className='col' key={nft.token_id}>
                  <div className='card d-flex justify-content-center'>
                    <Link to={`/objkt/token?contract=${nft.fa_contract}&token_id=${nft.token_id}`}>
                      <LazyLoadImage
                        effect='blur'
                        className='token-img'
                        src={(nft.mime === 'application/x-directory') ? `/nft/${nft.fa_contract}/${nft.token_id}.png` : (nft.mime && nft.mime.startsWith('image/'))
                          ? `/nft/${nft.artifact_uri.replace("ipfs://", "")}.${nft.mime.split('/')[1] === 'jpeg' ? 'jpg' : nft.mime.split('/')[1]}`
                          : `/nft/${nft.artifact_uri.replace("ipfs://", "")}`} alt={nft.name}
                        loading="lazy"
                      />
                    </Link>
                    <div className='card-body'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <div className='card-title fs-4 fw-bold'>
                          <Link to={`/objkt/token?contract=${nft.fa_contract}&token_id=${nft.token_id}`}>{nft.name}</Link>
                        </div>
                        <div className='card-subtitle text-body-secondary fs-6'>
                          {nft.supply}x
                        </div>
                      </div>
                      <div className='d-flex justify-content-between py-1 fs-6'>
                        <div className='d-flex flex-column'>
                          <div className='text-body-secondary'>Price:</div>
                          <div className='fw-bold'>{nft.listings && nft.listings.length > 0 ? formatPrice(nft.listings[0].price_xtz) + ' XTZ' : '-'}</div>
                        </div>
                        <div className='d-flex flex-column align-items-end'>
                          <div className='text-body-secondary'>Offer:</div>
                          <div className='fw-bold'>{nft.highest_offer ? formatPrice(nft.highest_offer) + ' XTZ' : '-'}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div ref={loaderRef}></div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default CollectionComponent;
