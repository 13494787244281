
import { useEffect, React } from 'react';
import './Hero.scss';
import gsap from 'gsap';

const HeroHome = () => {
  useEffect(() => {
    gsap.to('.title', { duration: 1, opacity: 1, y: 0, ease: "elastic.out(1,0.3)", });
  }, []);
  return (
    <>
      <section className='hero'>
        <div className='container'>
          <div className='title'>
            <p>👋 I'm Rocco Gallo aka Roccano.</p>
            <p>Welcome to my blockchain ⛓️‍💥 digital portfolio.</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroHome;
