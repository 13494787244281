import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './TokenComponent.scss';
import { HiSwitchVertical } from "react-icons/hi";
import { HiArrowsExpand } from "react-icons/hi";


const TokenComponent = () => {
  const [tokenData, setTokenData] = useState([]);
  const [contractParam, setContractParam] = useState(null);
  const [tokenParam, setTokenParam] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isImageFullScreen, setIsImageFullScreen] = useState(false);
  const [isIframeFullScreen, setIsIframeFullScreen] = useState(false);
  const iframeRef = React.createRef();

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.keyCode === 27) {
        if (isImageFullScreen) {
          setIsImageFullScreen(false);
        }
        if (isIframeFullScreen) {
          document.exitFullscreen();
          setIsIframeFullScreen(false);
        }
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [isImageFullScreen, isIframeFullScreen]);

  useEffect(() => {
    async function fetchData() {
      try {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        setContractParam(urlParams.get("contract"));
        setTokenParam(urlParams.get("token_id"));
        setLoading(true);

        const response = await fetch("https://data.objkt.com/v3/graphql", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `
              query MyQuery {
                token(
                  where: {fa: {contract: {_eq: "${urlParams.get('contract')}"}}, token_id: {_eq: "${urlParams.get('token_id')}"}}
                ) {
                  artifact_uri
                  average
                  decimals
                  description
                  display_uri
                  flag
                  highest_offer
                  is_boolean_amount
                  last_listed
                  last_metadata_update
                  level
                  lowest_ask
                  metadata
                  mime
                  name
                  ophash
                  rights
                  supply
                  symbol
                  thumbnail_uri
                  timestamp
                  tzip16_key
                  token_id
                  fa_contract
                  offers_active {
                    price_xtz
                  }
                  listings(where: {status: {_eq: "active"}}, order_by: {price_xtz: asc}) {
                    price_xtz
                    status
                  }
                  fa {
                    name
                  }
                  holders {
                    holder {
                      logo
                      alias
                      address
                    }
                    quantity
                  }
                  events(order_by: {timestamp: desc}) {
                    id
                    marketplace_event_type
                    timestamp
                    price_xtz
                    amount
                    creator_address
                    recipient_address
                    creator {
                      alias
                      address
                    }
                    recipient {
                      alias
                      address
                    }
                    event_type
                  }
                }
              }
            `,
          }),
        });

        const { data } = await response.json();
        setTokenData(data.token);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [contractParam, tokenParam]);

  const handleIframeFullScreen = () => {
    if (iframeRef.current) {
      if (!document.fullscreenElement) {
        iframeRef.current.requestFullscreen().then(() => {
          setIsIframeFullScreen(true);
        }).catch((err) => {
          console.error(`Error attempting to enable fullscreen mode: ${err.message} (${err.name})`);
        });
      } else {
        document.exitFullscreen().then(() => {
          setIsIframeFullScreen(false);
        }).catch((err) => {
          console.error(`Error attempting to exit fullscreen mode: ${err.message} (${err.name})`);
        });
      }
    }
  };

  if (loading) return <div className='container'><p>Loading...</p></div>;
  if (error) return <div className='container'><p>Error: {error.message}</p></div>;

  const formatPrice = (price) => {
    const formattedPrice = (price / 1000000).toFixed(2);
    const finalPrice = formattedPrice.endsWith('.00') ? formattedPrice.split('.')[0] : formattedPrice;
    return finalPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const calculateTimeDifference = (timestamp) => {
    const currentTime = new Date();
    const eventTime = new Date(timestamp);
    const timeDifference = currentTime - eventTime;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days}d`;
    } else if (hours > 0) {
      return `${hours}h`;
    } else if (minutes > 0) {
      return `${minutes}m`;
    } else {
      return `${seconds}s`;
    }
  };

  return (
    <>
      <Helmet>
        {tokenData.map((token) => (
          <title key={token.token_id}>{token.name}</title>
        ))}
      </Helmet>
      <section className='token-detail'>
        <div className='container'>
          <div className="token-detail-container py-4">
            {tokenData.map((token) => (
              <div key={token.token_id}>
                {/* Wrap Token Detail */}
                <div className='wrap-token-detail'>
                  {token.mime === 'application/x-directory' ? (
                    <div className='frame-token-detail'>
                      <iframe
                        ref={iframeRef}
                        src={`/nft/${token.artifact_uri.replace("ipfs://", "")}/index.html`}
                        title={token.name}
                        className='token-iframe'
                      />
                      <button onClick={handleIframeFullScreen} className='fullscreen-button'>
                        <HiArrowsExpand />
                      </button>
                    </div>
                  ) : (
                    <div className='img-token-detail'>
                      {isImageFullScreen ? (
                        <div
                          className={`fullscreen-image-overlay ${isImageFullScreen ? "visible" : ""}`}
                          onClick={(e) => {
                            if (isImageFullScreen) {
                              // Chiudi l'immagine solo se è aperta e si fa clic fuori dall'immagine
                              setIsImageFullScreen(false);
                            }
                          }}
                        >
                          <div onClick={() => setIsImageFullScreen(false)} className="close-fullscreen-image">
                            Close
                          </div>
                          <img
                            src={(token.mime && token.mime.startsWith('image/')) ? `/nft/${token.artifact_uri.replace("ipfs://", "")}.${token.mime.split('/')[1] === 'jpeg' ? 'jpg' : token.mime.split('/')[1]}` : `/nft/${token.artifact_uri.replace("ipfs://", "")}`}
                            alt={token.name}
                            className='token-img-fullscreen'
                          />
                        </div>
                      ) : (
                        <div
                          onClick={() => setIsImageFullScreen(true)}
                          className="open-fullscreen-image"
                        >
                          <LazyLoadImage
                            effect='blur'
                            className='token-img'
                            src={(token.mime && token.mime.startsWith('image/')) ? `/nft/${token.artifact_uri.replace("ipfs://", "")}.${token.mime.split('/')[1] === 'jpeg' ? 'jpg' : token.mime.split('/')[1]}` : `/nft/${token.artifact_uri.replace("ipfs://", "")}`}
                            alt={token.name}
                            loading="lazy"
                          />
                        </div>
                      )}
                    </div>
                  )}
                  <div className='info-token-detail'>
                    <Link to={`/objkt/collection?contract=${token.fa_contract}`}>
                      <h5 className='token-collection'>{token.fa.name}</h5>
                    </Link>
                    <h2 className='token-name fw-bold'>{token.name}</h2>
                    <p className='token-description pt-4'>{token.description}</p>
                    <div className='row row-cols-1 row-cols-sm-2 g-2 pt-2 pb-4'>
                      <div className='col'>
                        <div className='card text-center p-2 h-100 justify-content-center'>
                          <div className='text-body-secondary fs-6'>Price:</div>
                          <div className='fw-bold fs-5'>{token.listings && token.listings.length > 0 ? formatPrice(token.listings[0].price_xtz) + ' XTZ' : '-'}</div>
                        </div>
                      </div>
                      <div className='col'>
                        <div className='card text-center p-2 h-100 justify-content-center'>
                          <div className='text-body-secondary fs-6'>Offer:</div>
                          <div className='fw-bold fs-5'>{token.highest_offer ? formatPrice(token.highest_offer) + ' XTZ' : '-'}</div>
                        </div>
                      </div>
                    </div>
                    <Link
                      to={`https://objkt.com/asset/${token.fa_contract}/${token.token_id}`}
                      className="token-cta p-"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View on Objkt
                    </Link>
                  </div>
                </div>
                {/* NavTab */}
                <Tabs className='pt-5'>
                  <TabList>
                    <Tab>Properties</Tab>
                    <Tab>Owners</Tab>
                    <Tab>History</Tab>
                  </TabList>
                  <TabPanel>
                    <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-2 py-2'>
                      <div className='col'>
                        <div className='card d-flex justify-content-center text-center p-4 h-100'>
                          <div className='fs-6'>Editions</div>
                          <div className='fw-bold fs-5'>{token.supply}</div>
                        </div>
                      </div>
                      <div className='col'>
                        <div className='card d-flex justify-content-center text-center p-4 h-100'>
                          <div className='fs-6'>Token id</div>
                          <div className='fw-bold fs-5'>{token.token_id}</div>
                        </div>
                      </div>
                      <div className='col'>
                        <div className='card d-flex justify-content-center text-center p-4 h-100'>
                          <div className='fs-6'>Minted</div>
                          <div className='fw-bold fs-5'>{new Date(token.timestamp).toLocaleString()}</div>
                        </div>
                      </div>
                      <div className='col'>
                        <div className='card d-flex justify-content-center text-center p-4 h-100'>
                          <div className='fs-6'>MIME Type</div>
                          <div className='fw-bold fs-5'>{token.mime}</div>
                        </div>
                      </div>
                      <div className='col'>
                        <div className='card d-flex justify-content-center text-center p-4 h-100'>
                          <Link
                            to={`https://tzkt.io/${token.fa_contract}`}
                            className='fs-5'
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Contract
                          </Link>
                        </div>
                      </div>
                      <div className='col'>
                        <div className='card d-flex justify-content-center text-center p-4 h-100'>
                          <a
                            href={token.artifact_uri.replace(token.artifact_uri, `https://assets.objkt.media/file/assets-003/${token.artifact_uri.replace("ipfs://", "")}/artifact`)}
                            className='fs-5'
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            CDN
                          </a>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-2 py-2'>
                      {token.holders.map((holder, index) =>
                        holder.quantity > 0 && (
                          <div className='col' key={index}>
                            <div className='card d-flex justify-content-center text-center p-4 h-100 fs-5'>
                              <div>
                                <Link to={`https://objkt.com/profile/${holder.holder.address}`}>
                                  <LazyLoadImage effect='blur' src={holder.holder.logo} alt={holder.alias} className='holder-img'></LazyLoadImage>
                                </Link>
                              </div>
                              <div>
                                {holder.holder.alias ? holder.holder.alias : (
                                  <span>
                                    {holder.holder.address.substring(0, 5)}...
                                    {holder.holder.address.slice(-5)}
                                  </span>
                                )}
                              </div>
                              <div>
                                {holder.quantity}
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className='table-responsive'>
                      <table className='table'>
                        <thead>
                          <tr>
                            <th scope="col">Last activity</th>
                            <th scope="col">Event</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Unit price</th>
                            <th scope="col">From/To</th>
                          </tr>
                        </thead>
                        <tbody>
                          {token.events.map((event, index) => {
                            let formattedPrice = '';
                            if (event.price_xtz !== null) {
                              const price = parseInt(event.price_xtz);
                              if (price >= 10000000 && price % 1000000 === 0) {
                                // Price has three non-zero leading digits followed by five zeros
                                formattedPrice = `${(price / 1000000).toFixed(2)}`;
                              } else if (price % 100000000 === 0) {
                                // Price has eight zeros
                                formattedPrice = `${price / 100000000}`;
                              } else if (price % 10000000 === 0) {
                                // Price has seven zeros
                                formattedPrice = `${price / 10000000}0`;
                              } else if (price % 1000000 === 0) {
                                // Price has six zeros
                                formattedPrice = `${price / 1000000}`;
                              } else if (price % 10000 === 0) {
                                // Price has four zeros
                                formattedPrice = `${(price / 1000000).toFixed(2)}`;
                              } else if (price >= 100000 && price < 1000000) {
                                // Price has one non-zero leading digit followed by five zeros
                                formattedPrice = `0.${(price / 100000).toFixed(2)}`;
                              } else {
                                formattedPrice = `${price / 1000000}`;
                              }
                              formattedPrice += " XTZ";
                            }
                            return (
                              <tr key={index}>
                                <td>
                                  {calculateTimeDifference(event.timestamp)} ago
                                </td>
                                <td>
                                  {event.event_type || event.marketplace_event_type}
                                </td>
                                <td>{event.amount}</td>
                                <td>{formattedPrice}</td>
                                <td>
                                  <div className='d-flex align-items-center'>
                                    <HiSwitchVertical />
                                    <div className="d-flex flex-column ps-2">
                                      <div>
                                        <Link target='_blank' to={`https://objkt.com/profile/${event.creator_address}`}>
                                          {event.creator.alias ? event.creator.alias : '-'}
                                        </Link>
                                      </div>
                                      <div>
                                        <Link target='_blank' to={`https://objkt.com/profile/${event.recipient_address}`}>
                                          {event.recipient_address ? (
                                            <span>
                                              {event.recipient.alias ? event.recipient.alias : (
                                                <>
                                                  {event.recipient_address.substring(0, 5)}...
                                                  {event.recipient_address.slice(-5)}
                                                </>
                                              )}
                                            </span>
                                          ) : (
                                            '-'
                                          )}
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default TokenComponent;
