import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { HiOutlineMoon, HiOutlineSun, HiMenu } from "react-icons/hi";
import { ThemeContext } from './ThemeContext';
import './Header.scss';

const Header = () => {
  const { isDarkMode, setIsDarkMode } = useContext(ThemeContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleThemeToggle = () => {
    setIsDarkMode(!isDarkMode);
    document.body.classList.toggle('dark-mode');
  };

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const themeIcon = isDarkMode ? <HiOutlineSun /> : <HiOutlineMoon />;

  return (
    <header className='sticky-top'>
      <nav className={`navbar navbar-expand-lg  ${isMenuOpen ? 'show' : ''}`}>
        <div className="container-fluid">
          <NavLink to="/" className='logo'>Roccano</NavLink>
          <button className="navbar-toggler" type="button" aria-controls="navbarSupportedContent" aria-expanded={isMenuOpen} aria-label="Toggle navigation" onClick={handleMenuToggle}>
            <HiMenu />
          </button>
          <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink to="/collections" className={"nav-link"} onClick={handleMenuToggle}>Collections</NavLink>
              </li>
              <div className='divider'></div>
              <li className="nav-item">
                <NavLink to="/about" className={"nav-link"} onClick={handleMenuToggle}>About</NavLink>
              </li>
            </ul>
            <ul className='navbar-nav'>
              <li onClick={handleThemeToggle} className='nav-item'>
                <button className='nav-link' aria-label="Toggle theme">
                  {themeIcon}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
