import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './Collections.scss';

function Collections() {
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchCollections() {
      try {
        setLoading(true);
        const response = await fetch('https://api.zora.co/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            query: `
            query ZoraCollections {
              collections(
                networks: {network: ZORA, chain: ZORA_MAINNET}
                where: {collectionAddresses: ["0x27171f079acda2d2043499d34f2f93400a836398", "0x25c0ad1c51e3f3fa4ebc8e268732c0f5ad549a58", "0x3e4aa5793efa0947b97416f20669d3339e8afe18"]}
              ) {
                nodes {
                  name
                  address
                }
              }
            }   
            `,
          }),
        });

        const data = await response.json();
        setCollections(data.data.collections.nodes);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }

    fetchCollections();
  }, []);

  if (loading) return <div className='container'><p>Loading...</p></div>;
  if (error) return <div className='container'><p>Error: {error.message}</p></div>;

  return (
    <>
      <section className='section section--collections'>
        <div className='container'>
          <div className='collections'>
            <div className='row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 g-3 my-4'>
              {collections.map((collection, index) => (
                <div key={collection.name} className='col'>
                  <Link
                    to={`/zora/collection?contract=${collection.address}`}
                    className='collection-item p-3 h-100 justify-content-center card'>
                    <LazyLoadImage effect='blur' className='collection-logo' src={'/collection/' + collection.address + '.png'} alt={collection.name} />
                    <h2 className='collection-title'>{collection.name}</h2>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>

      </section>
    </>
  );
}

export default Collections;
