import React from 'react';
import { NavLink } from 'react-router-dom';
import './Footer.scss';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <footer id='footer'>
        <div className='container-fluid d-flex justify-content-between align-items-center'>
          <div className='copy'>
            Roccano {currentYear} ©
          </div>
          <nav className='navbar'>
            <ul className='navbar-nav'>
              <li className='nav-item'>
                  <NavLink to="/faq" className='nav-link' activeclassname="active">Faq</NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </footer>
    </>
   
  );
};

export default Footer;
