import './About.scss';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { HiGlobeAlt } from "react-icons/hi";
import gsap from 'gsap';


const About = () => {

  useEffect(() => {
    gsap.fromTo('.avatar', { opacity: 0, y: 50 }, { duration: 0.3, opacity: 1, y: 0, ease: "power2.out", delay: 0.2 });
    gsap.fromTo('.location', { opacity: 0, y: 10 }, { duration: 0.3, opacity: 1, y: 0, ease: "power2.out", delay: 0.3 });
    gsap.fromTo('.phone', { opacity: 0, y: 10 }, { duration: 0.3, opacity: 1, y: 0, ease: "power2.out", delay: 0.5 });
    gsap.fromTo('.mail', { opacity: 0, y: 10 }, { duration: 0.3, opacity: 1, y: 0, ease: "power2.out", delay: 0.5 });
    gsap.fromTo('.about', { opacity: 0, y: 10 }, { duration: 0.3, opacity: 1, y: 0, ease: "power2.out", delay: 0.7 });
    gsap.fromTo('.bio', { opacity: 0, y: 10 }, { duration: 0.3, opacity: 1, y: 0, ease: "power2.out", delay: 0.8 });
    const webLinks = document.querySelectorAll('.web-links li');
    gsap.fromTo(webLinks,
      { opacity: 0, x: 10 },
      { opacity: 1, x: 0, duration: 0.3, ease: "power2.out", stagger: 0.2, delay: 0.8 }
    );
  }, []);


  return (
    <main className='main'>
      <Helmet>
        <title>About</title>
      </Helmet>
      <section className='section section--about'>
        <div className='container'>
          <div className='wrap'>
            <div className='item'>
              <div className='info'>
                <div className='name'>Rocco Gallo</div>
                <div className='location'><HiGlobeAlt /> Rimini, IT</div>
                <div className='phone'><strong>phone:</strong> +39 331 2353366</div>
                <div className='mail'><strong>mail:</strong> <a href='mailto:rocco@roccano.it'>rocco@roccano.it</a></div>
              </div>
              <div className='about'>
                <div className='title'>About</div>
                <p>I was born in 1991 in Mombasa, Kenya and grew up in a multicultural environment which has profoundly influenced my artistic work.</p>
                <p>My goal is to connect with viewers and inspire reflection on issues that are close to my heart through my digital artwork.</p>
              </div>
              <div className='bio'>
                <div className='title'>Bio</div>
                <p>As an artist, Rocco’s journey has been organic and self-guided. Although he did not undergo formal training in art, his creative pursuits have been a constant presence since childhood. Over the years, he has developed a unique style, drawing inspiration from various forms of art and culture. His foray into the web3 space came about through his exploration of new technologies and platforms. The decentralized nature of web3 and blockchain technology appealed to Rocco, aligning with his democratic and inclusive artistic vision. It provided him with an opportunity to connect directly with collectors, collaborate with fellow artists, and explore new avenues for artistic expression.</p>
                <p>Vivid colors, simplified forms, and playful imagery are hallmarks of Rocco’s art. His creations aim to capture the essence of emotions and experiences, transcending traditional artistic conventions. Through his art, Rocco portrays a world that is rich with imagination, inviting viewers to embrace their own sense of childlike wonder and curiosity. The visuality of his art serves the intent of evoking emotions and creating a sense of connection, transporting viewers to a realm where they can momentarily escape the constraints of reality and immerse themselves in the magic of their own imagination.</p>
                <p>In challenging traditional art conventions, Rocco embraces a more intuitive and untrained approach. By deviating from formal techniques and rules, he allows his creativity to flow freely, resulting in unexpected compositions, unconventional use of colors, and a sense of childlike spontaneity. His artworks reflect his personal experiences, emotions, and the world around him. While there may not be a specific common theme, a recurring message in his work is the celebration of imagination, joy, and the importance of embracing one’s inner child. Rocco aims to remind people of the magic and wonder that exists in the simplest things, inspiring them to reconnect with their own sense of wonder, curiosity, and playfulness.</p>
                <p>Written by UnknownCollector</p>
              </div>
            </div>
            <div className='item sticky'>
              <div className='avatar'>
                <img src="/avatar.jpg" alt='Rocco Gallo'></img>
              </div>
              <div className='web-links'>
                <ul>
                  <li>
                    <Link to="https://twitter.com/rocc_ano" target="_blank">Twitter</Link>
                  </li>
                  <li>
                    <Link to="https://www.instagram.com/roccano/" target="_blank">Instagram</Link>
                  </li>
                  <li>
                    <Link to="https://objkt.com/profile/roccano/" target="_blank">Objkt</Link>
                  </li>
                  <li>
                    <Link to="https://zora.co/0x522d1cB57EfC5F00ECA49742F25Df0392DF863FD" target="_blank">Zora</Link>
                  </li>
                  <li>
                    <Link to="https://foundation.app/@roccano" target="_blank">Foundation</Link>
                  </li>
                  <li>
                    <Link to="https://www.fxhash.xyz/u/Roccano" target="_blank">Fxhash</Link>
                  </li>
                  <li>
                    <Link to="https://highlight.xyz/user/@roccano.eth" target="_blank">Highlight</Link>
                  </li>
                  <li>
                    <Link to="https://www.warpcast.com/roccano/" target="_blank">Warpcast</Link>
                  </li>



                  <li>
                    <Link to="https://exchange.art/roccano" target="_blank">Exchange</Link>
                  </li>
                  <li>
                    <Link to="https://opensea.io/roccano" target="_blank">Opensea</Link>
                  </li>
                  <li>
                    <Link to="https://oncyber.io/@0x522d1cb57efc5f00eca49742f25df0392df863fd" target="_blank">Oncyber</Link>
                  </li>
                  <li>
                    <Link to="https://thehug.xyz/artists/roccano/" target="_blank">Hug</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='section section--tablist'>
        <div className='container'>
          <div className='wrap'>
            <div className='item'>
              <div className='title'>Interviews</div>
              <ul className='interviews'>
                <li>
                  <a href='https://log.fakewhale.xyz/dance-with-innocence-meet-rocco-gallo/' target='_blank' rel="noreferrer">
                    <div className='title'>Dance with Innocence – Meet Rocco Gallo</div>
                    <div className='info'>
                      <div className='date'>Sep 11, 2023</div>
                      <div className='author'>UnknownCollector</div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href='https://oneofoneart.substack.com/p/join-hands-start-a-lovechain' target='_blank' rel="noreferrer">
                    <div className='title'>Join Hands, Start a Lovechain</div>
                    <div className='info'>
                      <div className='date'>Jun 15, 2023</div>
                      <div className='author'>ONE OF ONE</div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div className='item'>
              <div className='title'>Exhibitions</div>
              <ul className='exhibitions'>
                <li>
                  <a href='https://www.nftcc.xyz/' target='_blank' rel="noreferrer">
                    <div className='title'>Nft_cc</div>
                    <div className='info'>
                      <div className='date'>2024</div>
                      <div className='author'>Roma</div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href='https://www.meetcenter.it/it/' target='_blank' rel="noreferrer">
                    <div className='title'>Meet - Digital Culture Center</div>
                    <div className='info'>
                      <div className='date'>2024</div>
                      <div className='author'>Milano</div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href='https://www.nftcc.xyz/' target='_blank' rel="noreferrer">
                    <div className='title'>Nft_cc</div>
                    <div className='info'>
                      <div className='date'>2024</div>
                      <div className='author'>Naples</div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href='https://foundation.app/gallery/links-between-worlds/exhibition/247' target='_blank' rel="noreferrer">
                    <div className='title'>Link between worlds</div>
                    <div className='info'>
                      <div className='date'>2023</div>
                      <div className='author'>Florence</div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href='https://foundation.app/collection/salida' target='_blank' rel="noreferrer">
                    <div className='title'>Salida</div>
                    <div className='info'>
                      <div className='date'>2022</div>
                      <div className='author'>Bologna</div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div className='item'>
              <div className='title'>Insights</div>
              <ul className='insights'>
                <li>
                  <a href='https://aura-nft.medium.com/roccano-and-the-digital-geometric-representation-d7771eeba762' target='_blank' rel="noreferrer">
                    <div className='title'>Roccano and the digital geometric representation</div>
                    <div className='info'>
                      <div className='date'>Apr 19, 2022</div>
                      <div className='author'>Finir</div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default About;
